import React from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import BannersLovelonet from '../modules/Banners/BannersLovelonet';
import Footer from '../components/Footer';

// Define el componente funcional BotonDescarga, que recibe props como parámetro
function BotonDescarga(props) {
  // Define la función handleButtonClick que se ejecuta cuando el botón es clicado
  const handleButtonClick = () => {
    // Abre el enlace en una nueva pestaña del navegador
    window.open(props.link, '_blank');
  };

  return (
    <Button className='button-morado' onClick={handleButtonClick}>
      {props.buttonText || 'Descargar'}
    </Button>
  );
}

const App = () => {
  return (
    <>
     {/*<BannersLovelonet /> */}
      <Container>       
        <Row className="my-5">
          <Col md={6}>
            <Image src="./assets/img/lovelonet/ticketera.png" fluid />
          </Col>
          <Col md={6}>
            <br />
            <h2 style={{ color: "#592582", textAlign: "left" }}>Ticketera de la Felicidad</h2>
            <p style={{ textAlign: "left" }}>
              En Velonet pensamos en ti, por eso premiamos tu pasión y entrega. Disfruta de beneficios increíbles en agradecimiento por tus resultados.
            </p>
            <ul style={{ textAlign: "left" }}>
              <li>• Horas libres</li>
              <li>• Cariñitos Velonet como helado gratis</li>
              <li>• Desconéctate temprano un viernes al mes</li>
              <li>• Y mucho más</li>
            </ul>
          </Col>
        </Row>

        <Row className="my-5">
          <Col md={6}>
            <br />
            <h2 style={{ color: "#592582", textAlign: "left" }}>Préstamos</h2>
            <p style={{ textAlign: "left" }}>
              Muchas veces necesitamos un dinerito extra, y sí, Velonet lo sabe. Por eso puedes solicitar un préstamo de hasta <strong style={{ color: "#592582" }}> $2.000.000.</strong> Conoce cómo en el documento del botón.
            </p>
            <BotonDescarga link="assets/doc/talentoHumano/Politicas de bienestar/Política de préstamos 2.pdf" buttonText="Ver más" />
          </Col>
          <Col md={6}>
            <Image src="./assets/img/lovelonet/prestamos.png" fluid />
          </Col>
        </Row>

        <Row className="my-5">
          <Col md={6}>
            <Image src="./assets/img/lovelonet/internet.png" fluid />
          </Col>
          <Col md={6}>
            <br />
            <h2 style={{ color: "#592582", textAlign: "left" }}>Descuentos en Servicios Velonet</h2>
            <p style={{ textAlign: "left" }}>
              Si adquieres el servicio de internet con Velonet, tienes hasta un <strong style={{ color: "#592582" }}> 25% </strong> de descuento con nosotros.
            </p>
            <BotonDescarga link="assets/doc/talentoHumano/Politicas de bienestar/Política de conectividad.pdf" buttonText="Ver más" />
          </Col>
        </Row>

        <Row className="my-5">
          <Col md={6}>
            <br />
            <h2 style={{ color: "#592582", textAlign: "left" }}>Beneficios de Antigüedad</h2>
            <p style={{ textAlign: "left" }}>
              Por pertenecer a Velonet, tienes aún más beneficios: vacaciones extra y recompensas monetarias para disfrutar. Conoce más en el documento.
            </p>
            <BotonDescarga link="assets/doc/talentoHumano/Politicas de bienestar/Política de Antiguedad.pdf" buttonText="Ver más" />
          </Col>
          <Col md={6}>
            <Image src="./assets/img/lovelonet/antiguedad.png" fluid />
          </Col>
        </Row>

        <Row className="my-5">
          <Col md={6}>
            <Image src="./assets/img/lovelonet/convenios.png" fluid />
          </Col>
          <Col md={6}>
            <br />
            <h2 style={{ color: "#592582", textAlign: "left" }}>Descuentos con Marcas Aliadas</h2>
            <p style={{ textAlign: "left" }}>
              Por ser parte de la familia Velonet, tienes descuentos imperdibles en marcas aliadas. Conócelos dando clic abajo.
            </p>
            <BotonDescarga link="/convenios" buttonText="Ver más" />
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default App;
