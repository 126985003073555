import React, { useState, useEffect } from 'react';
import ReservationForm from '../components/ReservationForm';
import ReservationList from '../components/ReservationList';
import ReservationCalendar from '../components/ReservationCalendar';
import { Container } from 'react-bootstrap';

const ReservarSala = () => {
    const [reservations, setReservations] = useState([]);
    const [editingIndex, setEditingIndex] = useState(null);
    const [editingReservation, setEditingReservation] = useState(null);

    const fetchReservations = async () => {
        try {
            const response = await fetch('http://localhost:5000/api/reservations');
            if (response.ok) {
                const data = await response.json();
                setReservations(data);
            } else {
                console.error('Error al obtener reservas');
            }
        } catch (error) {
            console.error('Error de conexión con el servidor:', error);
        }
    };

    useEffect(() => {
        fetchReservations();
    }, []);

    const addReservation = (reservation) => {
        if (editingIndex !== null) {
            const updatedReservations = reservations.map((res, index) =>
                index === editingIndex ? reservation : res
            );
            setReservations(updatedReservations);
            setEditingIndex(null);
        } else {
            setReservations([...reservations, reservation]);
        }
    };

    const editReservation = (id) => {
        const index = reservations.findIndex(res => res._id === id);
        setEditingIndex(index);
        setEditingReservation(reservations[index]);
    };

    const deleteReservation = async (id) => {
        try {
            const response = await fetch(`http://localhost:5000/api/reservations/${id}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                const updatedReservations = reservations.filter(reservation => reservation._id !== id);
                setReservations(updatedReservations);
                alert('Reserva eliminada exitosamente');
            } else {
                alert('Error al eliminar la reserva');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error de conexión con el servidor');
        }
    };

    return (
        <Container>
            <h1 style={{ color: "#592582" }}>Reserva de salas Velonet</h1>
            <br />
            <ReservationForm
                addReservation={addReservation}
                editingReservation={editingReservation}
                setEditingReservation={setEditingReservation}
            />
            <br />
            <ReservationList
                reservations={reservations}
                editReservation={editReservation}
                deleteReservation={deleteReservation}
            />
            <br />
            <ReservationCalendar reservations={reservations} />
        </Container>
    );
};

export default ReservarSala;
