import React from 'react';
import Calendar from 'react-calendar';
import { Container, Card } from 'react-bootstrap';
import 'react-calendar/dist/Calendar.css'; // Asegúrate de importar el CSS de react-calendar

const ReservationCalendar = ({ reservations }) => {
  // Convierte las fechas de las reservas a objetos Date
  const dateReservations = reservations.map(res => new Date(res.date));

  return (
    <Container fluid className="h-100 p-0">
      <Card className="h-100">
        <Card.Body className="d-flex flex-column h-100">
          <Card.Title  style={{ color: '#592582'}}>Calendario de Reservas</Card.Title>
          <div className="flex-grow-1">
            <Calendar
              className="w-100 h-100"
              tileContent={({ date, view }) =>
                view === 'month' && dateReservations.some(d => d.toDateString() === date.toDateString()) ? (
                  <div style={{ backgroundColor: '#592582' , color: '#fff'}}>
                    Hay una reserva este día
                  </div>
                ) : null
              }
            />
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ReservationCalendar;
