import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer style={{ backgroundColor: '#592582', color: '#FFF', padding: '20px 0' }}>
      <Container>
        <Row className="text-center">
          <Col>
            <img as={Link} to="/"
              src={process.env.PUBLIC_URL + "/assets/img/logos/logo-velonet-blanco.png"}
              width="100"
              className="d-inline-block align-top"
              alt="Logo Blanco Velonet"
            />
            <br></br>
            <br></br>
            <p>Copyright © 2024 Elaborado por: Innovación y Desarrollo Velonet   v.1.0.4</p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="d-flex justify-content-center">
            <Link to="https://www.facebook.com/velonet.co">
              <img
                src={process.env.PUBLIC_URL + "/assets/img/footer/facebook.png"}
                alt="Facebook"
                width="30"
                style={{ margin: '0 10px' }}
              />
            </Link>
            <Link to="https://www.instagram.com/velonet.co/">
              <img
                src={process.env.PUBLIC_URL + "/assets/img/footer/instagram.png"}
                alt="Instagram"
                width="30"
                style={{ margin: '0 10px' }}
              />
            </Link>
            <Link to="https://www.youtube.com/@velonetesmas">
              <img
                src={process.env.PUBLIC_URL + "/assets/img/footer/youtube.png"}
                alt="Instagram"
                width="38"
                style={{ margin: '0 10px' }}
              />
            </Link>
            <Link to="https://x.com/velonetcolombia">
              <img
                src={process.env.PUBLIC_URL + "/assets/img/footer/twitter.png"}
                alt="Twitter"
                width="35"
                style={{ margin: '0 10px' }}
              />
            </Link>
            <Link to="https://www.linkedin.com/company/velonet-colombia/mycompany/">
              <img
                src={process.env.PUBLIC_URL + "/assets/img/footer/linkedin.png"}
                alt="Linkedin"
                width="30"
                style={{ margin: '0 10px' }}
              />
            </Link>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
