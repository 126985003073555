// src/components/ExampleCarouselImage.js

import React from 'react';

function ExampleCarouselImage({ src, alt, text }) {
  return (
    <div className="carousel-image-container" style={{ position: 'relative'}}>
      <img src={src} alt={alt} className='d-block w-100' style={{maxHeight: '640px', objectFit: 'cover'}}/>
<div className='carrusel-caption' style={{position: 'absolute', bottom:'20px', left:'20px', color: '#fff'}}>
      {text}
    </div>
    </div>
  );
}

export default ExampleCarouselImage;
