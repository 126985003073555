// src/modules/Banners/Banners.jsx

import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import ExampleCarouselImage from '../../components/ExampleCarouselImage'; // Ajusta la ruta para apuntar correctamente

function Banners() {
  return (
    <Carousel>
      <Carousel.Item>
        <ExampleCarouselImage src="/assets/img/banners/banner1.png" text="" />
        <Carousel.Caption>
          <h3></h3> 
          <p></p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <a href="/buzonsugerencias">
        <ExampleCarouselImage src="/assets/img/banners/banner3.png" text="" />
        </a>
        <Carousel.Caption>
          <h3></h3>
          <p></p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <ExampleCarouselImage src="/assets/img/banners/banner2.png" text="" />
        <Carousel.Caption>
          <h3></h3>
          <p></p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default Banners;
