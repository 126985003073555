import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import CardsDownload from '../modules/Cards/CardsDownload'; // Ruta corregida
import Footer from '../components/Footer';


const TalentoHumano = () => {
  return (
    <>
    <Container>
      <Row>
      <Col md={4}>
        <CardsDownload
            title="Reglamento Interno de Trabajo"
            bodyText="Explora este importante documento"
            buttonText="Descargar"
            margin="20px"
            imageSrc="/assets/img/tarjetasTalentoHumano/reglamento.png"
            link="../assets/doc/talentoHumano/reglamento_interno/reglamento_interno_trabajo.pdf"
          />
        </Col>
      <Col md={4}>
          <CardsDownload
            title="Comité de Convivencia"
            bodyText="Diligencia este formato para detalles tu situación"
            buttonText="Descargar"
            margin="20px"
            imageSrc="/assets/img/tarjetasTalentoHumano/comite.png"
            link="../assets/doc/talentoHumano/Reporte-de-Quejas-y-Sugerencias-al-CCL.pdf"
          />
        </Col>
        <Col md={4}>
          <CardsDownload
            title="Día de la familia"
            bodyText="Descarga el formato para solicitar el día de la Familia"
            buttonText="Descargar"
            margin="20px"
            imageSrc="/assets/img/tarjetasTalentoHumano/familia.png"
            link="../assets/doc/talentoHumano/formatos/formato-dia-de-la-familia.pdf"
          />
        </Col>
        <Col md={4}>
          <CardsDownload
            title="Vacaciones"
            bodyText="Descarga el formato para legalizar tus vacaciones"
            buttonText="Descargar"
            margin="20px"
            imageSrc="/assets/img/tarjetasTalentoHumano/vacaciones.png"
            link="../assets/doc/talentoHumano/formatos/22. Solicitud de Vacaciones.pdf"
          />
        </Col>
        <Col md={4}>
          <CardsDownload
            title="Licencia no remunerada"
            bodyText="Solicita una Licencia No Remunerada con este documento"
            buttonText="Descargar"
            margin="20px"
            imageSrc="/assets/img/tarjetasTalentoHumano/licencia.png"
            link="../assets/doc/talentoHumano/formatos/17. Solicitud de Licencia no Remunerada.pdf"
          />
        </Col>
        <Col md={4}>
        <CardsDownload
            title="Licencia Remunerada"
            bodyText="Disfruta de una Licencia Remunerada"
            buttonText="Descargar"
            margin="20px"
            imageSrc="/assets/img/tarjetasTalentoHumano/licenciaRemunearda.png"
            link="../assets/doc/talentoHumano/formatos/26. Solicitud de Licencia Remunerada.pdf"
          />
        </Col>
        <Col md={4}>
        <CardsDownload
            title="Permisos Cortos"
            bodyText="Gestiona un permiso corto con este documento"
            buttonText="Descargar"
            margin="20px"
            imageSrc="/assets/img/tarjetasTalentoHumano/permisoCorto.png"
            link="../assets/doc/talentoHumano/formatos/26. Formato_Solicitud_Permisos_Cortos.pdf"
          />
        </Col>
      </Row>
     </Container>
     <Footer />
    </>
  );
};

export default TalentoHumano;
