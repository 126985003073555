// src/components/AllCollapseExample.js

import React from 'react';
import { Container } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';

function AllCollapse() {
  return (
    <Container>
      <Accordion>
        <Accordion.Item eventKey="0" >
          <Accordion.Header>Nuestra Misión</Accordion.Header>
          <Accordion.Body>
            Llegar con tecnología de última generación, a sectores donde existe un potencial de crecimiento para brindar el servicio de internet.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Nuestra Visión</Accordion.Header>
          <Accordion.Body>
          Somos la empresa líder en el desarrollo de fibra óptica y para el 2025 seremos la empresa con mayor cantidad de usuarios conectados a internet con esa tecnología.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
}

export default AllCollapse;
