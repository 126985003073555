import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import CardsConvenios from '../modules/Cards/CardsConvenios'; // Ruta corregida
import Footer from '../components/Footer';
import CardsImagen from '../modules/Cards/CardsImagen';


const CulturaVelonet = () => {
  return (
    <>
      <Container>
        <h1 style={{ color: "#592582" }}>Cultura Velonet</h1>
        <p>Compartiendo lo que nos mueve</p>
        <Row>
          <Col md={4}>
            <CardsConvenios
              title="Buzón de sugerencias"
              buttonText="Ir al Buzón"
              margin="20px"
              imageSrc="/assets/img/culturaVelonet/buzon.jpg"
              link="/buzonsugerencias"
            />
          </Col>
          <Col md={4}>
            <CardsConvenios
              title="Correo corporativo"
              buttonText="Ir al Correo"
              margin="20px"
              imageSrc="/assets/img/culturaVelonet/correoCorporativo.jpg"
              link="https://accounts.google.com/v3/signin/identifier?continue=https%3A%2F%2Fmail.google.com%2Fmail%2F&ifkv=AS5LTAQJOrhQdxw6Pxa9nGn-7okHAoE_0SnWCv57omwPncQq21rBCu_8QrjyudewlCU6L4RDXhee&rip=1&sacu=1&service=mail&flowName=GlifWebSignIn&flowEntry=ServiceLogin&dsh=S1896813822%3A1719942952150902&ddm=0"
            />
          </Col>
          <Col md={4}>
            <CardsImagen
              title="Respeto"
              margin="20px"
              imageSrc="/assets/img/culturaVelonet/respeto.jpg"
            />
          </Col>
          <Col md={4}>
            <CardsImagen
              title="Sonreir"
              margin="20px"
              imageSrc="/assets/img/culturaVelonet/sonreir.jpg"
            />
          </Col>
          <Col md={4}>
            <CardsImagen
              title="Tips Velonet"
              margin="20px"
              imageSrc="/assets/img/culturaVelonet/tiposVelonet.jpg"
            />
          </Col>
          <Col md={4}>
            <CardsImagen
              title="Trabajo en Equipo"
              margin="20px"
              imageSrc="/assets/img/culturaVelonet/trabajoEquipo.jpg"
            />
          </Col>
          <Col md={4}>
            <CardsImagen
              title="Uniforme"
              margin="20px"
              imageSrc="/assets/img/culturaVelonet/uniforme.jpg"
            />
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default CulturaVelonet;
