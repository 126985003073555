// Pagina Directorio
import React from 'react';
import DataTable from '../components/DataTable';
import { Container } from 'react-bootstrap';
import Footer from '../components/Footer';

function Directory() {
  return (
    <div className="App">
      <h1 style={{ color: "#592582" }}>Directorio Velonet</h1>
      <p>Ingresa el nombre, área o cargo de un colaborador en la barra de búsqueda para encontrar sus datos</p>
      <Container />
      <DataTable />
      <Footer />
    </div>
  );
}

export default Directory;
