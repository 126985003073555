import React from 'react'; // Importa React
import Button from 'react-bootstrap/Button'; // Importa el componente Button de react-bootstrap
import Card from 'react-bootstrap/Card'; // Importa el componente Card de react-bootstrap
import { Link } from 'react-router-dom'; // Importa Link desde react-router-dom

// Define el componente Cards
function CardsImagen(props) {
  return (
    // Renderiza un componente Card de Bootstrap
    <Card style={{ backgroundColor: "#FAEFFF" , width: '22rem', margin: props.margin || '100px' }}>
      {/* Renderiza una imagen dentro del Card */}
      <Card.Img variant="top" src={props.imageSrc || process.env.PUBLIC_URL + "/assets/img/icono-directorio.png"} style={{ width: '700px', margin: 'auto', padding: '20px'}}/>
      {/* Renderiza el cuerpo del Card */}
      <Card.Body>
        {/* Renderiza el título del Card */}
        <Card.Title>{props.title || 'Titulo'}</Card.Title>
        <br>
        </br>
        {/* Renderiza un botón dentro del Card */}        
      </Card.Body>
    </Card>
  );
}

export default CardsImagen; // Exporta el componente Cards por defecto
