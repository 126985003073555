import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import CardsConvenios from '../modules/Cards/CardsConvenios'; // Ruta corregida
import Footer from '../components/Footer';


const Convenios = () => {
  return (
    <>
    <Container>
      <h1 style={{ color: "#592582" }}>Convenios Velonet</h1>
      <p>Disfruta de estos descuentos pensados para que disfrutes de beneficios con grandes marcas</p>
      <Row>
        <Col md={4}>
          <CardsConvenios
            title="Carulla"
            buttonText="Visitar sitio web"
            margin="20px"
            imageSrc="/assets/img/convenios/carulla.jpeg"
            link="https://www.carulla.com/"
          />
        </Col>
        <Col md={4}>
          <CardsConvenios
            title="Drogerías Cafam"
            buttonText="Visitar sitio web"
            margin="20px"
            imageSrc="/assets/img/convenios/Droguerias cafam.jpeg"
            link="https://www.drogueriascafam.com.co/"
          />
        </Col>
        <Col md={4}>
          <CardsConvenios
            title="Olimpica"
            buttonText="Visitar sitio web"
            margin="20px"
            imageSrc="/assets/img/convenios/olimpica.jpeg"
            link="https://www.olimpica.com/"
          />
        </Col>
        <Col md={4}>
          <CardsConvenios
            title="Planetario de Medellín"
            buttonText="Visitar sitio web"
            margin="20px"
            imageSrc="/assets/img/convenios/planetario.jpeg"
            link="https://www.olimpica.com/"
          />
        </Col>
        <Col md={4}>
          <CardsConvenios
            title="Universal"
            buttonText="Visitar sitio web"
            margin="20px"
            imageSrc="/assets/img/convenios/universal.jpeg"
            link="https://www.hogaruniversal.com/"
          />
        </Col>
        <Col md={4}>
          <CardsConvenios
            title="Veterinario en su hogar"
            buttonText="Visitar sitio web"
            margin="20px"
            imageSrc="/assets/img/convenios/veterinaria.jpeg"
            link="https://www.veterinarioensuhogar.com/"
          />
        </Col>
        <Col md={4}>
          <CardsConvenios
            title="Dermalife"
            buttonText="Visitar sitio web"
            margin="20px"
            imageSrc="/assets/img/convenios/dermalife.jpg"
            link="https://dermalife.co/"
          />
        </Col>
        <Col md={4}>
          <CardsConvenios
            title="Hotel Primavera Santa Fe"
            buttonText="Reservar"
            margin="20px"
            imageSrc="/assets/img/convenios/hotel-primavera-santafe.jpg"
            link="https://wa.link/oe8mi1"
          />
        </Col>
      </Row>
    </Container>
    <Footer />
    </>
  );
};

export default Convenios;
