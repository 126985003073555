import React from 'react'; // Importa la librería React
import Button from 'react-bootstrap/Button'; // Importa el componente Button de react-bootstrap
import Card from 'react-bootstrap/Card'; // Importa el componente Card de react-bootstrap

// Define el componente funcional CardsDownload, que recibe props como parámetro
function CardsDownload(props) {
  // Define la función handleButtonClick que se ejecuta cuando el botón es clicado
  const handleButtonClick = () => {
    // Abre el enlace en una nueva pestaña del navegador
    window.open(props.link, '_blank');
  };

  return (
    // Renderiza un componente Card de Bootstrap con estilos personalizados
    <Card style={{ backgroundColor: "#FAEFFF", width: '18rem', margin: props.margin || '100px' }}>
      {/* Renderiza una imagen dentro del Card */}
      <Card.Img
        variant="top"
        src={props.imageSrc || process.env.PUBLIC_URL + "/assets/img/icono-directorio.png"}
        style={{ width: '100px', margin: 'auto', padding: '20px' }}
      />
      {/* Renderiza el cuerpo del Card */}
      <Card.Body>
        {/* Renderiza el título del Card */}
        <Card.Title>{props.title || 'Titulo'}</Card.Title>
        {/* Renderiza el texto del cuerpo del Card */}
        <Card.Text>
          {props.bodyText || 'Some quick example text to build on the card title and make up the bulk of the card\'s content.'}
        </Card.Text>
        {/* Renderiza un botón dentro del Card */}
        {/* Utiliza onClick para manejar el evento de clic */}
        <Button className='button-morado' onClick={handleButtonClick}>
          {props.buttonText || 'Go somewhere'}
        </Button>
      </Card.Body>
    </Card>
  );
}

export default CardsDownload; // Exporta el componente CardsDownload por defecto

