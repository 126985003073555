import React from 'react';
import { Container, Table, Button } from 'react-bootstrap';

const ReservationList = ({ reservations, editReservation, deleteReservation }) => {
  return (
    <Container>
      <Table striped bordered hover responsive className="mt-4">
        <thead>
          <tr>
            <th>Nombre de reserva</th>
            <th>Sala</th>
            <th>Fecha</th>
            <th>Hora de inicio</th>
            <th>Hora final</th>
            <th>Correo</th>
            <th>Motivo</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {reservations.map((res) => (
            <tr key={res._id}>
              <td>{res.name}</td>
              <td>{res.office}</td>
              <td>{res.date}</td>
              <td>{res.startTime}</td>
              <td>{res.endTime}</td>
              <td>{res.email}</td>
              <td>{res.reason}</td>
              <td>
                <Button 
                  variant="primary" 
                  className="me-2 button-morado" 
                  onClick={() => editReservation(res._id)}
                >
                  Editar
                </Button>
                <Button 
                  variant="danger" 
                  className="button-naranja" 
                  onClick={() => deleteReservation(res._id)}
                >
                  Eliminar
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default ReservationList;
