import React, { useState } from 'react'; // Importa React y useState desde React
import data from '../data/dataDirectory.json'; // Importa los datos del directorio desde el archivo JSON
import Table from 'react-bootstrap/Table'; // Importa el componente de tabla de Bootstrap
import { Container, Form, Button } from 'react-bootstrap'; // Importa Container, Form y Button de Bootstrap

// Define el componente DataTable
const DataTable = () => {
  const [searchTerm, setSearchTerm] = useState(''); // Declara el estado para el término de búsqueda
  const [filteredData, setFilteredData] = useState(data); // Declara el estado para los datos filtrados, inicialmente igual a todos los datos

  // Maneja el cambio en el término de búsqueda
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value); // Actualiza el término de búsqueda según lo que el usuario ingrese en el input
  };

  // Maneja la búsqueda de datos
  const handleSearch = () => {
    const filtered = data.filter(item => {
      // Convierte cada propiedad del objeto item a minúsculas y las compara con el término de búsqueda
      const nombreEmpleado = item.nombreEmpleado ? item.nombreEmpleado.toString().toLowerCase() : '';
      const area = item.area ? item.area.toString().toLowerCase() : '';
      const cargo = item.cargo ? item.cargo.toString().toLowerCase() : '';
      const correo = item.correo ? item.correo.toString().toLowerCase() : '';
      const celular = item.celular ? item.celular.toString().toLowerCase() : '';
      const ext = item.ext ? item.ext.toString().toLowerCase() : '';

      // Devuelve true si alguna propiedad coincide con el término de búsqueda
      return (
        nombreEmpleado.includes(searchTerm.toLowerCase()) ||
        area.includes(searchTerm.toLowerCase()) ||
        cargo.includes(searchTerm.toLowerCase()) ||
        correo.includes(searchTerm.toLowerCase()) ||
        celular.includes(searchTerm.toLowerCase()) ||
        ext.includes(searchTerm.toLowerCase())
      );
    });
    setFilteredData(filtered); // Actualiza los datos filtrados con los resultados de la búsqueda
  };

  // Maneja la limpieza de la búsqueda
  const handleClear = () => {
    setSearchTerm(''); // Limpia el término de búsqueda
    setFilteredData(data); // Restablece los datos filtrados para mostrar todos los datos nuevamente
  };

  // Renderiza el componente DataTable
  return (
    <Container>
      {/* Formulario de búsqueda */}
      <Form.Group controlId="searchBar" style={{ margin: '20px 0', borderColor: '#592582'}}>
        <Form.Control
          type="text"
          placeholder="Buscar..."
          value={searchTerm}
          onChange={handleSearchChange}
          style={{ display: 'inline-block', width: 'auto', marginRight: '10px' }}
        />
        {/* Botón de búsqueda */}
        <Button className='button-morado' onClick={handleSearch} style={{ marginRight: '10px' }}>Buscar</Button>
        {/* Botón de limpieza */}
        <Button className='button-naranja' onClick={handleClear}>Borrar</Button>
      </Form.Group>
      {/* Tabla de datos */}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Nombre Empleado</th>
            <th>Área</th>
            <th>Cargo</th>
            <th>Correo</th>
            <th>Celular</th>
            <th>Extensión</th>
          </tr>
        </thead>
        <tbody>
          {/* Renderiza las filas de la tabla con los datos filtrados */}
          {filteredData.length > 0 ? (
            filteredData.map((item, index) => (
              <tr key={index}>
                <td>{item.nombreEmpleado}</td>
                <td>{item.area}</td>
                <td>{item.cargo}</td>
                <td>{item.correo}</td>
                <td>{item.celular}</td>
                <td>{item.ext}</td>
              </tr>
            ))
          ) : (
            // Si no hay datos filtrados, muestra una fila con un mensaje indicando que no hay datos disponibles
            <tr>
              <td colSpan="6">No hay datos disponibles</td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
};

export default DataTable; // Exporta el componente DataTable por defecto
