import React from 'react'; // Importa React
import Button from 'react-bootstrap/Button'; // Importa el componente Button de react-bootstrap
import Card from 'react-bootstrap/Card'; // Importa el componente Card de react-bootstrap
import { Link } from 'react-router-dom'; // Importa Link desde react-router-dom

// Define el componente Cards
function Cards(props) {
  return (
    // Renderiza un componente Card de Bootstrap
    <Card style={{ backgroundColor: "#FAEFFF" , width: '18rem', margin: props.margin || '100px' }}>
      {/* Renderiza una imagen dentro del Card */}
      <Card.Img variant="top" src={props.imageSrc || process.env.PUBLIC_URL + "/assets/img/icono-directorio.png"} style={{ width: '100px', margin: 'auto', padding: '20px'}}/>
      {/* Renderiza el cuerpo del Card */}
      <Card.Body>
        {/* Renderiza el título del Card */}
        <Card.Title>{props.title || 'Titulo'}</Card.Title>
        {/* Renderiza el texto del cuerpo del Card */}
        <Card.Text>{props.bodyText || 'Some quick example text to build on the card title and make up the bulk of the card\'s content.'}</Card.Text>
        {/* Renderiza un botón dentro del Card */}
        {/* Utiliza Link para convertir el botón en un enlace */}
        <Button className='button-morado' as={Link} to={props.link}>{props.buttonText || 'Go somewhere'}</Button>
      </Card.Body>
    </Card>
  );
}

export default Cards; // Exporta el componente Cards por defecto
