import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import emailjs from 'emailjs-com'; 
import { v4 as uuidv4 } from 'uuid'; 

const formatTime = (date) => {
  const hours = date.getHours() % 12 || 12;
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = date.getHours() < 12 ? 'AM' : 'PM';
  return `${hours}:${minutes} ${ampm}`;
};

const generateTimeSlots = () => {
  const slots = [];
  let startTime = new Date();
  startTime.setHours(7, 30, 0, 0); 

  for (let i = 0; i < 22; i++) { 
    const time = new Date(startTime.getTime() + i * 30 * 60 * 1000);
    slots.push(formatTime(time));
  }

  return slots;
};

const timeSlots = generateTimeSlots();

const ReservationForm = ({ addReservation, editingReservation, setEditingReservation }) => {
  const [reservation, setReservation] = useState({
    _id: '',  
    name: '',
    office: 'Sala de juntas',
    date: '',
    startTime: '',
    endTime: '',
    email: '',
    reason: '',
  });

  useEffect(() => {
    if (editingReservation) {
      setReservation(editingReservation);
    }
  }, [editingReservation]);

  useEffect(() => {
    if (reservation.startTime) {
      const [startHours, startMinutes, startPeriod] = reservation.startTime.split(/[:\s]/);
      const startTime = new Date(`${reservation.date}T${(parseInt(startHours) % 12 + (startPeriod === 'PM' ? 12 : 0)).toString().padStart(2, '0')}:${startMinutes.padStart(2, '0')}:00`);
  
      const endTime = new Date(startTime);
      endTime.setHours(endTime.getHours() + 1);
  
      setReservation((prev) => ({
        ...prev,
        endTime: formatTime(endTime),
      }));
    }
  }, [reservation.startTime, reservation.date]);

  const handleChange = (e) => {
    setReservation({ ...reservation, [e.target.name]: e.target.value });
  };

  const convertToDateTime = (date, time) => {
    const [hours, minutes, period] = time.split(/[:\s]/);
    const dateTime = new Date(`${date}T${(parseInt(hours) % 12 + (period === 'PM' ? 12 : 0)).toString().padStart(2, '0')}:${minutes.padStart(2, '0')}:00`);
    return dateTime;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const now = new Date();
    const reservationStart = convertToDateTime(reservation.date, reservation.startTime);
    const reservationEnd = convertToDateTime(reservation.date, reservation.endTime);

    if (reservationStart < now) {
      alert('No se puede crear una reserva en una fecha y hora anterior al momento actual.');
      return;
    }

    if (reservationEnd <= reservationStart) {
      alert('La hora de finalización debe ser posterior a la hora de inicio.');
      return;
    }

    const newReservation = { ...reservation, _id: reservation._id || uuidv4() };

    try {
      const response = await fetch('http://localhost:5000/api/reservations', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newReservation),
      });

      if (response.ok) {
        const templateParams = {
          name: newReservation.name,
          office: newReservation.office,
          date: newReservation.date,
          startTime: newReservation.startTime,
          endTime: newReservation.endTime,
          email: newReservation.email,
          reason: newReservation.reason,
        };

        emailjs.send('service_mx1et2i', 'template_qhmmp6s', templateParams, 'KF15er5T6tNfbdfte')
          .then((result) => {
            console.log(result.text);
            alert('Reserva creada exitosamente');
          }, (error) => {
            console.log(error.text);
            alert('Se creó la reserva, pero no se pudo enviar el correo');
          });

        addReservation(newReservation);

        setReservation({
          _id: '',
          name: '',
          office: 'Sala de juntas',
          date: '',
          startTime: '',
          endTime: '',
          email: '',
          reason: '',
        });

        setEditingReservation(null);
      } else {
        alert('Error al crear la reserva');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error de conexión con el servidor');
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="3">Nombre</Form.Label>
          <Col sm="9">
            <Form.Control
              type="text"
              name="name"
              value={reservation.name}
              onChange={handleChange}
              placeholder="Quien reserva"
              required
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="3">Sala a reservar</Form.Label>
          <Col sm="9">
            <Form.Control as="select" name="office" value={reservation.office} onChange={handleChange}>
              <option value="Sala de juntas">Sala de juntas</option>
               {/* <option value="Sala">Sala de reuniones (secundaria)</option> */}
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="3">Fecha</Form.Label>
          <Col sm="9">
            <Form.Control
              type="date"
              name="date"
              value={reservation.date}
              onChange={handleChange}
              required
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="3">Hora de inicio</Form.Label>
          <Col sm="3">
            <Form.Control
              as="select"
              name="startTime"
              value={reservation.startTime}
              onChange={handleChange}
              required
            >
              {timeSlots.map((slot) => (
                <option key={slot} value={slot}>{slot}</option>
              ))}
            </Form.Control>
          </Col>

          <Form.Label column sm="3">Hora final</Form.Label>
          <Col sm="3">
            <Form.Control
              as="select"
              name="endTime"
              value={reservation.endTime}
              onChange={handleChange}
              required
            >
              {timeSlots.map((slot) => (
                <option key={slot} value={slot}>{slot}</option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="3">Correo de notificación</Form.Label>
          <Col sm="9">
            <Form.Control
              type="email"
              name="email"
              value={reservation.email}
              onChange={handleChange}
              placeholder="Correo de notificación"
              required
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="3">Motivo (Observaciones)</Form.Label>
          <Col sm="9">
            <Form.Control
              as="textarea"
              name="reason"
              value={reservation.reason}
              onChange={handleChange}
              placeholder="Motivo (Observaciones)"
            />
          </Col>
        </Form.Group>

        <Button type="submit" className='button-morado'>
          {reservation._id ? 'Actualizar Reserva' : 'Crear Reserva'}
        </Button>
      </Form>
    </Container>
  );
};

export default ReservationForm;
