import React, { useState, useEffect } from 'react';
import cedulasValidas from '../data/cedulas-validas.json'; // Importa el archivo JSON con cédulas válidas
import { Container, Row, Col, Form, Button, Alert, Card } from 'react-bootstrap'; // Importa componentes necesarios de React Bootstrap



// Define el componente funcional Login, que recibe la función onLogin como prop
const Login = ({ onLogin }) => {
  // Estados locales del componente
  const [cedula, setCedula] = useState(''); // Estado para almacenar la cédula ingresada por el usuario
  const [error, setError] = useState(''); // Estado para manejar mensajes de error
  const [validCedulas, setValidCedulas] = useState([]); // Estado para almacenar las cédulas válidas cargadas desde el archivo JSON

  // useEffect se utiliza para cargar las cédulas válidas cuando el componente se monta
  useEffect(() => {
    // Carga las cédulas válidas desde el archivo JSON
    setValidCedulas(cedulasValidas.cedulas);
  }, []);

  // Función que se ejecuta cuando se envía el formulario de inicio de sesión
  const handleSubmit = (event) => {
    event.preventDefault(); // Evita que el formulario se envíe automáticamente

    // Verifica si la cédula ingresada está en la lista de cédulas válidas
    if (validCedulas.includes(cedula.trim())) {
      onLogin(); // Llama a la función onLogin pasada como prop (indicando que el inicio de sesión fue exitoso)
    } else {
      setError('Cédula incorrecta'); // Establece un mensaje de error si la cédula no es válida
    }
  };

  // Renderiza el formulario de inicio de sesión y gestiona los estados
  return (
    <Container>
      {/* Contenedor para centrar la tarjeta en el centro de la página */}
      <Row className="justify-content-md-center mt-5">
        <Col md={6}>
          {/* Tarjeta de Bootstrap para el formulario de inicio de sesión */}
          <Card style={{ alignItems: "center", backgroundColor: "#FAEFFF" }}>
            <br></br>
            {/* Imagen del logo en la parte superior de la tarjeta */}
            <Card.Img style={{ width: "300px", textAlign: "center" }} variant="top" src="./assets/img/logos/logoLogin.png" alt="Logo" />
            <Card.Body>
              {/* Título y subtítulo del formulario */}
              <h1 style={{ color: '#592582' }} className="text-center mb-4">Bienvenido a la Intranet Velonet</h1>
              <h4 style={{ textAlign: "center" }}>Iniciar sesión</h4>
              <br></br>
              {/* Formulario para ingresar la cédula */}
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formCedula">
                  <Form.Label>Ingresa tu cédula como contraseña</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Contraseña"
                    value={cedula}
                    onChange={(e) => setCedula(e.target.value)}
                    required
                  />
                </Form.Group>
                {/* Alerta de error en caso de cédula incorrecta */}
                {error && <Alert variant="danger">{error}</Alert>}
                {/* Botón para enviar el formulario */}
                <Button
                  variant="primary"
                  type="submit"
                  className="w-100 mt-3"
                  style={{ backgroundColor: '#592582' }} // Color de fondo inicial
                  onMouseOver={(e) => e.target.style.backgroundColor = '#F7931E'} // Cambio de color al pasar el mouse
                  onMouseOut={(e) => e.target.style.backgroundColor = '#592582'} // Restaura el color al quitar el mouse
                >
                  Ingresar
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Login; // Exporta el componente Login por defecto para poder ser utilizado en otras partes de la aplicación
