import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Container, Button } from 'react-bootstrap';
import emailjs from 'emailjs-com';
import Footer from '../components/Footer';



function BuzonSugerencias() {
  const [name, setName] = useState('');
  const [suggestion, setSuggestion] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (suggestion.trim() === '') {
      setError('La sugerencia es un campo obligatorio');
      return;
    }

    const templateParams = {
      name: name,
      suggestion: suggestion,
    };

    emailjs.send('service_mx1et2i', 'template_2mp80uo', templateParams, 'KF15er5T6tNfbdfte')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        alert('Sugerencia enviada con éxito!');
        setName('');
        setSuggestion('');
        setError('');
      }, (err) => {
        console.log('FAILED...', err);
        alert('Hubo un error al enviar la sugerencia. Por favor, intenta de nuevo.');
      });
  };

  return (
    <>
    <Container>
      <div>
        <h1 style={{ color: "#592582" }}>Buzón de Sugerencias (Anónimas)</h1>
        <p>Tu sugerencia puede ser anónima o puedes poner tu nombre, es opcional. Gracias por ayudar a construir un mejor Velonet con tus aportes</p>
        <br />
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Nombre (si es anónima no escribir el nombre) </Form.Label>
            <Form.Control
              type="text"
              placeholder="Nombre (opcional)"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Sugerencia</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder='Describa su sugerencia'
              value={suggestion}
              onChange={(e) => setSuggestion(e.target.value)}
              required
            />
          </Form.Group>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <Button className='button-morado' type="submit">
            Enviar Sugerencia
          </Button>
        </Form>
      </div>
      <br>
      </br>
      <br>
      </br>
    </Container>
    <Footer />
    </>
  );
}

export default BuzonSugerencias;
