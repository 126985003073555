// src/modules/AccordionModule/AccordionModule.jsx

import React from 'react';
import Accordion from '../modules/Accordion/Accordion';

function AccordionModule() {
  return (
    <div>
        <br />
      <h2 style={{color: "#592582"}}>Velonet más cerca es mejor</h2>  
      <p>Conoce a continuación lo que nos mueve como empresa</p>    
      <Accordion />
    </div>
  );
}

export default AccordionModule;
